"use client";

import { LoginForm } from "@/components/login-form"
import { useSearchParams } from "next/navigation";
import { CheckCircle2 } from "lucide-react";

export default function Page() {
  const searchParams = useSearchParams();
  const isVerified = searchParams.get('verified') === 'true';

  return (
    <div className="flex min-h-svh w-full items-center justify-center p-6 md:p-10">
      <div className="w-full max-w-sm space-y-4">
        {isVerified && (
          <div className="flex items-center gap-2 rounded-lg bg-green-500/10 p-4 text-green-500">
            <CheckCircle2 className="h-5 w-5" />
            <p className="text-sm">Email verified successfully! Please log in.</p>
          </div>
        )}
        <LoginForm />
      </div>
    </div>
  )
}
