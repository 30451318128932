"use client"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setToken } from "@/lib/store/authSlice"
import { useRouter, useSearchParams } from "next/navigation"
import Cookies from 'js-cookie'
import { RootState } from "@/lib/store"

export function LoginForm({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  
  const dispatch = useDispatch()
  const router = useRouter()
  const searchParams = useSearchParams()
  const { token, isAuthenticated } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    if (isAuthenticated && token) {
      router.push("/")
    }

    const initialMessage = searchParams.get('message')
    if (initialMessage === 'need-to-verify-email') {
      setMessage('Please verify your email address before logging in. Check your email for a verification link.')
    }
  }, [isAuthenticated, token, router, searchParams])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError("")
    setMessage("")
    setIsLoading(true)

    try {
      const formData = new FormData()
      formData.append('email', email)
      formData.append('password', password)

      const response = await fetch(`${process.env.NEXT_PUBLIC_API_DOMAIN}/auth/login/`, {
        method: "POST",
        body: formData
      })

      const data = await response.json()

      if (response.ok) {
        const token = data["key"]
        dispatch(setToken(token))
        
        // Set cookie
        Cookies.set('atk', token, {
          expires: 7,
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
          secure: true,
          sameSite: 'strict'
        })

        router.push("/")
      } else {
        const errorString = Object.values(data as Record<string, string[]>)
          .flat()
          .join('\n')
        setError(errorString)
      }
    } catch (err) {
      console.error('An error occurred during login:', err)
      setError('An error occurred during login. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={cn("flex flex-col gap-6", className)} {...props}>
      <Card className="glass-container bg-black/20 backdrop-blur-[40px] shadow-[0_8px_32px_rgba(0,0,0,0.2)] border-white/10">
        <CardHeader>
          <CardTitle className="text-2xl text-white/90">Welcome back</CardTitle>
          <CardDescription className="text-white/60">
            Enter your credentials below to access your account
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-6">
              {error && (
                <div className="text-red-500 text-sm bg-red-500/10 p-3 rounded-lg border border-red-500/20">
                  {error}
                </div>
              )}
              {message && (
                <div className="text-green-500 text-sm bg-green-500/10 p-3 rounded-lg border border-green-500/20">
                  {message}
                </div>
              )}
              <div className="grid gap-2">
                <Label htmlFor="email" className="text-white/80">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="name@domain.com"
                  required
                  className="bg-black/40 border-white/10 text-white placeholder:text-white/30 focus:ring-white/20"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password" className="text-white/80">Password</Label>
                  <a
                    href="/reset-password"
                    className="ml-auto inline-block text-sm text-white/60 hover:text-white/90 underline-offset-4 hover:underline transition-colors"
                  >
                    Forgot password?
                  </a>
                </div>
                <Input 
                  id="password" 
                  type="password" 
                  placeholder="Enter your password"
                  required 
                  className="bg-black/40 border-white/10 text-white placeholder:text-white/30 focus:ring-white/20"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <Button 
                type="submit" 
                className="w-full bg-white/10 hover:bg-white/20 text-white transition-all hover:shadow-[0_8px_20px_rgba(255,255,255,0.1)]"
                disabled={isLoading}
              >
                {isLoading ? "Signing in..." : "Sign in"}
              </Button>
            </div>
            <div className="mt-6 text-center text-sm text-white/60">
              Don&apos;t have an account?{" "}
              <a href="/signup" className="text-white/90 hover:text-white underline underline-offset-4 transition-colors">
                Sign up
              </a>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  )
}
